// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-albums-photos-js": () => import("./../../../src/pages/albums-photos.js" /* webpackChunkName: "component---src-pages-albums-photos-js" */),
  "component---src-pages-elus-js": () => import("./../../../src/pages/elus.js" /* webpackChunkName: "component---src-pages-elus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/article-list.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-event-list-js": () => import("./../../../src/templates/event-list.js" /* webpackChunkName: "component---src-templates-event-list-js" */),
  "component---src-templates-images-gallery-js": () => import("./../../../src/templates/images-gallery.js" /* webpackChunkName: "component---src-templates-images-gallery-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-portal-js": () => import("./../../../src/templates/portal.js" /* webpackChunkName: "component---src-templates-portal-js" */),
  "component---src-templates-publication-list-js": () => import("./../../../src/templates/publication-list.js" /* webpackChunkName: "component---src-templates-publication-list-js" */),
  "component---src-templates-tags-article-list-js": () => import("./../../../src/templates/tags-article-list.js" /* webpackChunkName: "component---src-templates-tags-article-list-js" */)
}

